@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dangrek&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Julee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');

/* font-family: 'Alegreya Sans', sans-serif; */
/* font-family: 'Cinzel', serif; */
/* font-family: 'Righteous', cursive; */
/* font-family: 'Dangrek', cursive; */
/* font-family: 'Dancing Script', cursive; */
/* font-family: 'Lobster', cursive; */
/* font-family: 'Pacifico', cursive; */
/* font-family: 'Shadows Into Light', cursive; */
/* font-family: 'Caveat', cursive; */
/* font-family: 'Indie Flower', cursive; */
/* font-family: 'Satisfy', cursive; */
/* font-family: 'Ms Madi', cursive; */
/* font-family: 'Julee', cursive; */
/* font-family: 'Patua One', cursive; */
/* font-family: 'Cookie', cursive; */
/* font-family: 'Gloria Hallelujah', cursive; */




/* width */
::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(226, 226, 226);
}

/* Track */
::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(68, 68, 68, 0.432);
}

.book-holder {
    width: 24em;
    height: 17em;
    margin: 1em 1em;
    padding: 1em 1em 1em 1em;
    background: rgb(226, 226, 226);
    position: relative;
    /* overflow: hidden; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid rgba(106, 106, 106, 0.452);
    border-radius: 15px;
    box-shadow: 0px 0px 4px rgba(106, 106, 106, 0.275);
    transition: 0.5s cubic-bezier(0.42, 0.18, 0.41, 0.95);
}

.book-holder:hover {
    box-shadow: 5px 5px 10px rgba(96, 96, 96, 0.275);
    transform: translate(-5px, -5px);
}

.poster {
    width: 200px;
    height: 260px;
    margin: 0px 20px 0px 0px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(77, 77, 77, 0.479);
    border-radius: 10px;
    transition: box-shadow 0.5s ease-in-out;
}

.book-holder:hover .poster {
    box-shadow: 0 0 10px rgb(117, 117, 117);
}

.manga-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.manga-name {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 100;
    color: rgb(68, 68, 68);
    width: fit-content;
    font-family: "Dangrek", cursive;
}

.mangaKa-name {
    font-size: 18px;
    font-weight: 100;
    color: rgb(68, 68, 68);
    font-family: "Satisfy", cursive;
    width: fit-content;
    margin: 5px 0px 5px 0;
    align-self: flex-end;
}

.manga-synopsis {
    overflow-y: scroll;
    font-family: "Alegreya Sans", sans-serif;
    height: 178px;
}


.rating {
    height: 30px;
    width: 30px;
    padding: 5px;
    position: absolute;
    left: 170px;
    top: 5px;
    font-weight: 900;
    font-family: 'Julee', cursive;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
    /* box-shadow: 0 0 7px #fca605 */
    /* #143bca #05fc70 #0ee41c #89fc05 #fca605 #fc3f05 #fc0505 */
}


.rating h1 {
    font-size: 30px;
    /* text-align: center; */
    position: relative;
    z-index: 1;
    /* color: rgb(39, 49, 46); */
    font-size: 15px;
    transform: translateY(10%);
}

.rating .wave {
    width: 200px;
    height: 200px;
    /* background-color: #05fc70e1; */
    /* #444857
    #3fbb47
    #fca605
    #fc3f05
    #05fc70 */
    box-shadow: inset 0 0 50px rgba(255, 255, 255, 0.6);
    position: absolute;
    left: -15px;
    top: -80px;
    transition: 0.4s;
}
.book-holder:hover .wave {
    top: -100px;
    left: -30px;
}
.rating .wave::before,
.rating .wave::after {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 80px;
    transform: translate(-50%, -75%);
}

.rating .wave::before {
    border-radius: 45%;
    background-color: rgba(235, 235, 235, 0.5);
    animation: wave 5s ease-in infinite;
}

.rating .wave::after {
    border-radius: 35%;
    background-color: rgba(235, 235, 235, 0.5);
    animation: wave 10s ease-in infinite;
}

@-webkit-keyframes wave {
    0% {
        transform: translate(-50%, -75%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -75%) rotate(360deg);
    }
}

@keyframes wave {
    0% {
        transform: translate(-50%, -75%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -75%) rotate(360deg);
    }
}