.shelf {
  display: flex;
  width: 900px;
  flex-wrap: wrap;
  height: 643px;
  /* overflow: hidden; */
  position: relative;
}

.shelf-track {
  z-index: 0;
  display: flex;
  width: 900px;
  flex-wrap: wrap;
  height: fit-content;
  position: relative;
  transition: transform 0.4s cubic-bezier(0.36, 0.34, 0.12, 0.97);
}

.buttonImage{
  all: unset;
  position: absolute;
  /* height: fit-content; */
  height: 20px;
  align-self: center;
  top: 50%;
  transform: translateY(-40%);
  z-index: 5;
  transition: 0.3s ease-out;
}

.shelf div img[disabled] {
  opacity: 0.2;
}

.buttonImage:first-of-type {
  left: 0;
}

.buttonImage:last-of-type {
  right: 0;
}
