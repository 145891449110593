.carousel{
    height: 300px;
    width: 1200px;
    margin: 70px 0px 20px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.carousel-track{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1200px;
    height: fit-content;
    transition: 0.5s ease-in-out;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
}

.carousel-track div{
    width: 100%;
    height: 300px;
}

.carousel-track div:nth-of-type(1){
    background-color: rgba(255  , 255, 255, 0.5);
}

.carousel-track div:nth-of-type(2){
    background-color: rgba(255, 0, 0, 0.5);
}

.carousel-track div:nth-of-type(3){
    background-color: rgba(00, 255, 00, 0.5);
}

.carousel-track div:nth-of-type(4){
    background-color: rgba(0, 0, 255, 0.5);
}

.carousel-track div:nth-of-type(5){
    background-color: rgba(0, 0, 0, 0.5);
}

.carousel-nav{
    width: fit-content;
    position: absolute;
    display: block;
    bottom: 50%;
    right: 15px;
    transform: translateY(45%);
}

.carousel-nav span{
    opacity: 0.8;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    background-color: rgba(237, 237, 237, 0.595);
    margin: 10px 0px 10px 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.538);
}
.carousel-nav span:hover{
    background-color: rgba(255, 255, 255, 0.7);
}

.carousel-nav__selected{
    background-color: rgba(255, 255, 255, 0.9) !important;
}