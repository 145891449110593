/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}
html {
  font-family: 'Noto Sans JP', sans-serif;
}
*/
body {
  margin: 0;
  background-color: #3f3f3f;
}

#overlays {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}