@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

.quote-body {
  color: white;
  width: 300px;
  height: fit-content;
  padding: 20px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  text-align: center;
  /* border: 1px solid rgba(106, 106, 106, 0.075); */
  box-shadow: 0px 0px 8px rgba(106, 106, 106, 0.675);
  transition: 0.5s cubic-bezier(0.42, 0.18, 0.41, 0.95);
}

.quote-body:hover {
  box-shadow: 5px 5px 10px rgba(96, 96, 96, 0.475);
  transform: translate(-5px, -5px);
}

.quote-manga,
.quote-speaker {
  position: absolute;
  font-family: "Satisfy", cursive;
}

.quote-speaker {
  bottom: 20px;
  right: 20px;
}

.quote-manga {
  top: 20px;
  left: 20px;
}

.quote-content {
  padding: 30px;
  font-family: "Dancing Script", cursive;
  /* text-decoration: blink; */
  /* -webkit-animation: 3s infinite alternate flBlink; */
}

.quote-content span {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 40px;
  animation: 1s infinite alternate flBlink;
}

.quote-body:hover .quote-content span {
  animation: none;
}

.grad {
  position: relative;
  z-index: 1;
  background-image: linear-gradient(to top, #4568dc, #b06ab3);
  border-radius: 10px;
}

.grad::before {
  position: absolute;
  content: "";
  z-index: -1;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #b06ab3, #4568dc);
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.5s linear;
}

.grad:hover::before {
  opacity: 1;
}

@keyframes flBlink {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.05;
  }
  10% {
    opacity: 0.1;
  }
  15% {
    opacity: 0.15;
  }
  20% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.25;
  }
  30% {
    opacity: 0.3;
  }
  35% {
    opacity: 0.35;
  }
  40% {
    opacity: 0.4;
  }
  45% {
    opacity: 0.45;
  }
  50% {
    opacity: 0.5;
  }
  55% {
    opacity: 0.55;
  }
  60% {
    opacity: 0.6;
  }
  65% {
    opacity: 0.65;
  }
  70% {
    opacity: 0.7;
  }
  75% {
    opacity: 0.75;
  }
  80% {
    opacity: 0.8;
  }
  85% {
    opacity: 0.85;
  }
  90% {
    opacity: 0.9;
  }
  95% {
    opacity: 0.95;
  }
  100% {
    opacity: 1;
  }
}
