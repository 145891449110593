.BookBlock{
    opacity: 0;
    transition: all 0.5s linear;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    z-index: -1;
}
.activePage{
    opacity: 100;
    transition-delay: 0.2s;
    z-index: 1;
}