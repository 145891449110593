@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

.nav {
  width: 100vw;
  height: 50px;
  background-color: rgb(249, 78, 118);
  padding: 0;
  margin: 0;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.364);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
}
.active {
  transform: translateY(0px);
}
.hidden {
  transform: translateY(-50px);
}

.theme-change {
  all: unset;
  display: flex;
  padding: 0px 0;
  margin: 0 20px;
}

.theme-change img {
  margin: 0;
  padding: 0;
  height: 33px;
  margin: auto;
}

.profile-pic {
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 4px black;
  padding: 0px;
}
.profile-menu {
  font-family: "Indie Flower", cursive;
  width: 280px;
  height: 300px;
  position: fixed;
  top: 55px;
  right: 50%;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.345);
  transform: translateX(calc(50% + 12px));
  transition: 0.5s cubic-bezier(0.47, 0.34, 0, 0.92);
  overflow: hidden;
  font-size: 20px;
  -webkit-transform: translateX(calc(50% + 12px));
  -moz-transform: translateX(calc(50% + 12px));
  -ms-transform: translateX(calc(50% + 12px));
  -o-transform: translateX(calc(50% + 12px));
}
.profile-menu__nameLvl {
  margin: 0 0 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-menu__nameLvl button {
  all: unset;
  font-size: 30px;
  border-radius: 50%;
  padding: 12px 12px;
  height: 21px;
  box-shadow: 0 0 10px black;
}
.profile-menu__belong {
  margin: 10px 0;
}
.profile-menu__status {
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 20px 0;
}

.profile-menu__status div {
  padding: 10px 20px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgb(105, 105, 105);
  position: relative;
}
.profile-menu__status div:last-of-type {
  border-right: none;
}

.profile-menu__status div button {
  all: unset;
  padding: 5px;
  border-radius: 2px;
  border: 1px solid rgb(164, 164, 164);
  transition: 0.3s ease-in-out;
}

.profile-menu__status div h1 {
  position: absolute;
  bottom: 0px;
}
.setting,
.logOut {
  all: unset;
  width: 100%;
  text-align: center;
  padding: 10px 0 7px;
  margin: 0 0 5px;
}
.setting:hover,
.logOut:hover,
.profile-menu__status:hover {
  background-color: rgb(231, 231, 231);
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 100;
  font-size: 15px;
  font-family: "Pacifico", cursive;
}

.search input {
  all: unset;
  position: absolute;
  right: 50px;
  padding: 3px 15px;
  border-radius: 8px;
  background-color: aliceblue;
  transition: 0.5s cubic-bezier(0.47, 0.34, 0, 0.92);
}

.search-button {
  all: unset;
  display: inline-flex;
}

.search-button img {
  height: 50px;
}
