.shelf {
  display: flex;
  width: 420px;
  flex-wrap: wrap;
  height: 520px;
  overflow: hidden;
  position: relative;
}

.shelf-track {
  z-index: 0;
  display: flex;
  width: 900px;
  flex-wrap: wrap;
  height: fit-content;
  position: relative;
  justify-content: center;
  transition: transform 0.4s cubic-bezier(0.36, 0.34, 0.12, 0.97);
}

.shelf div button {
  all: unset;
  position: absolute;
  height: fit-content;
  align-self: center;
  top: 50%;
  right: 50%;
  transform: translate(60%,-40%);
  z-index: 5;
  transition: 0.3s ease-out;
}

.shelf div button[disabled] {
  opacity: 0.2;
}

.shelf button img {
  height: 25px;
}
